import React, { useState, useEffect } from "react";
import { permitHolderService } from "../functions/services";
import Cookies from "js-cookie";
import { authHeader } from '../functions/_helpers';

function SummaryPage() {
  const [licenseHolders, setLicenseHolders] = useState([]);
  const [selectedLicenseHolderId, setSelectedLicenseHolderId] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(getPreviousMonth());
  const [summaryData, setSummaryData] = useState([]);
  const [licenseHolderName, setLicenseHolderName] = useState("");
  const [error, setError] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [selectedCustomerId, setSelectedCustomerId] = useState(null);
  const [selectedSupplierId, setSelectedSupplierId] = useState(null);



  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const code = queryParams.get("code");
    console.log("Code fra URL:", code);

    if (code) {
      exchangeCodeForToken(code);
    } else {
      authenticateWithVisma();
    }
  }, []);

  const handleSendData = async () => {
    try {
      const token = getTokenFromCookies();
  
      if (!summaryData?.summary?.length) {
        throw new Error("Ingen data tilgjengelig i summaryData.");
      }
  
      if (!selectedCustomerId || !selectedSupplierId) {
        throw new Error("CustomerId eller SupplierId mangler.");
      }
  
      const mvaRate = 12;
      const mvaFactor = mvaRate / 100;
  
      const totalUtbetaling = summaryData.summary.reduce(
        (acc, item) =>
          acc +
          item.omsetningsavgift +
          item.taxameterCost +
          item.licenseCost +
          (summaryData.medlemsAvgift || 0),
        0
      );
  
      const mvaAmount = totalUtbetaling * mvaFactor;
      const eksMva = totalUtbetaling - mvaAmount;
  
      const roundedTotal = parseFloat(totalUtbetaling.toFixed(2));
      const roundingError = roundedTotal - totalUtbetaling;
  
      const supplierInvoiceRows = [
        { 
          AccountNumber: 2400, 
          CreditAmount: parseFloat(roundedTotal.toFixed(2)),
          DebitAmount : 0        
        },
        { 
          AccountNumber: 4010, 
          CreditAmount: 0,
          DebitAmount : parseFloat(eksMva.toFixed(2))        
        },
        { 
          AccountNumber: 2713, 
          CreditAmount: 0,
          DebitAmount : parseFloat(mvaAmount.toFixed(2))       
         }
      ];
  
      if (Math.abs(roundingError) > 0.01) {
        supplierInvoiceRows.push({
          AccountNumber: 7395,
          CreditAmount: 0,
          DebitAmount : parseFloat(roundingError.toFixed(2))
        });
      }
      const customerInvoiceRows = [
        {
          ArticleId: summaryData.summary[0]?.omsetningsavgiftArticleId,
          Quantity: 1,
          UnitPrice: summaryData.summary.reduce(
            (acc, item) => acc + item.omsetningsavgift,
            0
          ),
          VatRate: mvaRate,
        },
        {
          ArticleId: summaryData.summary[0]?.lisenserArticleId,
          Quantity: summaryData.summary.filter((item) => item.licenseCost > 0).length,
          UnitPrice: summaryData.summary.reduce(
            (acc, item) => acc + item.licenseCost,
            0
          ),
          VatRate: mvaRate,
        },
        {
          ArticleId: summaryData.summary[0]?.medlemsavgiftArticleId,
          Quantity: 1,
          UnitPrice: summaryData.medlemsAvgift || 0,
          VatRate: mvaRate,
        },
        {
          ArticleId: summaryData.summary[0]?.bilpakkeArticleId,
          Quantity: summaryData.summary.length, 
          UnitPrice: summaryData.summary.reduce(
            (acc, item) => acc + item.fixedCosts,
            0
          ),
          VatRate: mvaRate,
        }
      ];
  
      const payload = {
        VismaToken: token,
        CustomerId: selectedCustomerId,
        SupplierId: selectedSupplierId,
        CustomerInvoiceRows:customerInvoiceRows,
        SupplierInvoiceRows: supplierInvoiceRows,
        TotalAmount: roundedTotal,
      };
  
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/visma/create-drafts`,
        {
          method: "POST",
          headers: {...authHeader(),
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(payload),
        }
      );
  
      if (!response.ok) {
        const errorMessage = await response.text();
        throw new Error(`Feil ved opprettelse av fakturautkast: ${errorMessage}`);
      }
  
      const result = await response.json();
      alert("Fakturautkast opprettet!");
      console.log("Fakturautkast opprettet:", result);
    } catch (error) {
      console.error("Feil ved sending av data:", error.message);
      alert(`Noe gikk galt: ${error.message}`);
    }
  };

  const fetchLicenseHolderDetails = async () => {
    try {
      const token = getTokenFromCookies();
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/Visma/PermitHoldersWithCustomerAndSupplierIds`,
        {
          headers: {
            ...authHeader(), 
            "X-Visma-Token": token, 
          },
        }
      );
  
      if (!response.ok) {
        throw new Error("Kunne ikke hente permit holders fra API-et.");
      }
  
      const data = await response.json();
      console.log("Permit holders with customer and supplier IDs:", data);
      setLicenseHolders(data);

    } catch (error) {
      console.error("Feil ved henting av permit holders:", error.message);
      setError("Kunne ikke hente permit holders. Prøv igjen senere.");
    }
  };

  const authenticateWithVisma = () => {
    console.log("Starter autentisering med Visma.");
    const authorizationUrl = `https://identity.vismaonline.com/connect/authorize?response_type=code&client_id=intercabas&redirect_uri=https://localhost:44300/callback&scope=ea:accounting ea:api offline_access ea:sales ea:purchase
`;
    window.location.href = authorizationUrl;
  };

  const getTokenFromCookies = () => {
    return Cookies.get("access_token") || localStorage.getItem("access_token");
  };

  const saveTokenToCookies = (key, token, options = {}) => {
    const isProduction = process.env.NODE_ENV === "production";
    const defaultOptions = isProduction
      ? { secure: true, sameSite: "None" }
      : {};
    Cookies.set(key, token, { ...defaultOptions, ...options });

    localStorage.setItem(key, token);
  };
  const handleDeleteData = async () => {
   
  };

  const exchangeCodeForToken = async (code) => {
    try {
      console.log("Bytter kode for token:", code);
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/Visma/exchange-token`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ Code: code }),
        }
      );

      if (!response.ok) {
        const responseData = await response.json();
        throw new Error(
          `Feil under token-utveksling: ${response.statusText}. Detaljer: ${JSON.stringify(
            responseData
          )}`
        );
      }

      const responseData = await response.json();
      console.log("Respons fra tokenutveksling:", responseData);

      saveTokenToCookies("access_token", responseData.access_token);
      saveTokenToCookies("refresh_token", responseData.refresh_token, {
        expires: 7,
      });

      const storedToken = Cookies.get("access_token");
      if (storedToken) {
        setIsAuthenticated(true);
        console.log("Autentisering vellykket.");
      } else {
        throw new Error("Token ble ikke lagret riktig.");
      }
    } catch (error) {
      console.error("Feil under tokenutveksling:", error.message);
      setError("Autentisering feilet. Prøv igjen.");
    }
  };

  useEffect(() => {
    const token = getTokenFromCookies();
    if (token) {
      setIsAuthenticated(true);
    } else {
      authenticateWithVisma();
    }
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      fetchLicenseHolderDetails();

    }
  }, [isAuthenticated]);


  useEffect(() => {
    if (!selectedLicenseHolderId) return;

    const fetchLicenseHolderDetails = async () => {
      try {
        const data = await permitHolderService.getPermitHolder(
          selectedLicenseHolderId
        );
        setLicenseHolderName(data.name);
      } catch (err) {
        setError(err.message);
      }
    };

    fetchLicenseHolderDetails();
  }, [selectedLicenseHolderId]);

  useEffect(() => {
    if (!selectedLicenseHolderId || !selectedMonth) return;

    const fetchSummary = async () => {
      try {
        const [year, month] = selectedMonth.split("-");
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/rig/${selectedLicenseHolderId}/invoice-summary?year=${year}&month=${month}`,
          { headers: { Authorization: `Bearer ${getTokenFromCookies()}` } }
        );
        if (!response.ok) {
          throw new Error("Kunne ikke hente summering for valgt måned.");
        }
        const data = await response.json();
        setSummaryData(data);
      } catch (err) {
        setError(err.message);
      }
    };

    fetchSummary();
  }, [selectedLicenseHolderId, selectedMonth]);

  function getPreviousMonth() {
    const now = new Date();
    const prevMonth = new Date(now.getFullYear(), now.getMonth() - 1, 1);
    return `${prevMonth.getFullYear()}-${String(
      prevMonth.getMonth() + 1
    ).padStart(2, "0")}`;
  }

  if (!isAuthenticated) {
    return (
      <div className="flex items-center justify-center h-screen">
        <p>Vennligst vent. Autentiserer mot Visma...</p>
      </div>
    );
  }

  return (
<div className="summary-page">
  {error && <div className="error text-red-500">{error}</div>}

  <h1 className="text-2xl font-bold mb-4">
    {licenseHolderName || "Oppsummering av løyvehavere"}
  </h1>

  <div className="filters mb-4">
        <label htmlFor="licenseHolder" className="block mb-2">
          Velg løyvehaver
        </label>
        <select
  id="licenseHolder"
  className="p-2 border border-gray-300 rounded mb-4"
  value={selectedLicenseHolderId || ""}
  onChange={(e) => {
    const selectedId = e.target.value;
    setSelectedLicenseHolderId(selectedId);

    const selectedHolder = licenseHolders.find(
      (holder) => holder.permitHolderId === parseInt(selectedId)
    );

    if (selectedHolder) {
      setSelectedCustomerId(selectedHolder.customerId || null);
      setSelectedSupplierId(selectedHolder.supplierId || null);
    } else {
      setSelectedCustomerId(null);
      setSelectedSupplierId(null);
    }
  }}
>
  {licenseHolders.map((holder) => (
    <option key={holder.permitHolderId} value={holder.permitHolderId}>
      {holder.company}
    </option>
  ))}
</select>

        <label htmlFor="month" className="block mb-2">
          Velg måned
        </label>
        <input
          id="month"
          type="month"
          className="p-2 border border-gray-300 rounded mb-4"
          value={selectedMonth}
          onChange={(e) => setSelectedMonth(e.target.value)}
        />
      </div>

      <div className="actions mb-8 flex gap-4">
        <button
          onClick={handleSendData}
          className="p-2 bg-blue-500 text-white rounded hover:bg-blue-600"
        >
          Send inn
        </button>
        <button
          onClick={handleDeleteData}
          className="p-2 bg-red-500 text-white rounded hover:bg-red-600"
        >
          Slett data for valgt måned
        </button>
      </div>

  {summaryData.summary && summaryData.summary.length > 0 ? (
    <>
      <div className="summary-data mb-8">
        <h2 className="text-lg font-bold mb-4">Innkjørt:</h2>
        <table className="w-full border-collapse border border-gray-400 mb-4">
          <thead>
            <tr>
              <th className="border border-gray-400 p-2">Løyvenummer</th>
              <th className="border border-gray-400 p-2">TT</th>
              <th className="border border-gray-400 p-2">Skole</th>
              <th className="border border-gray-400 p-2">Pasientreiser</th>
              <th className="border border-gray-400 p-2">Ordinær</th>
              <th className="border border-gray-400 p-2">Cash (Trekkes)</th>
              <th className="border border-gray-400 p-2">Sum</th>
            </tr>
          </thead>
          <tbody>
            {summaryData.summary.map((item, index) => {
              const totalRevenue =
                item.tt +
                item.skole +
                item.pasientreiser +
                item.ordinær -
                item.cash;

              return (
                <tr key={index}>
                  <td className="border border-gray-400 p-2">{item.permitNumber}</td>
                  <td className="border border-gray-400 p-2">{item.tt.toFixed(2)}</td>
                  <td className="border border-gray-400 p-2">{item.skole.toFixed(2)}</td>
                  <td className="border border-gray-400 p-2">
                    {item.pasientreiser.toFixed(2)}
                  </td>
                  <td className="border border-gray-400 p-2">
                    {item.ordinær.toFixed(2)}
                  </td>
                  <td className="border border-gray-400 p-2">
                    {`-${item.cash.toFixed(2)}`}
                  </td>
                  <td className="border border-gray-400 p-2">{totalRevenue.toFixed(2)}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <div className="expenses-data mb-8">
        <h2 className="text-lg font-bold mb-4">Utgifter:</h2>
        <table className="w-full border-collapse border border-gray-400">
          <thead>
            <tr>
              <th className="border border-gray-400 p-2">Løyvenummer</th>
              <th className="border border-gray-400 p-2">Omsetningsavgift</th>
              <th className="border border-gray-400 p-2">Takstameterleie</th>
              <th className="border border-gray-400 p-2">Bilpakke</th>
              <th className="border border-gray-400 p-2">Lisens</th>
              <th className="border border-gray-400 p-2">Sum</th>
            </tr>
          </thead>
          <tbody>
            {summaryData.summary.map((item, index) => {
              const totalExpenses =
                item.omsetningsavgift +
                item.taxameterCost +
                item.fixedCosts +
                item.licenseCost;

              return (
                <tr key={index}>
                  <td className="border border-gray-400 p-2">{item.permitNumber}</td>
                  <td className="border border-gray-400 p-2">
                    {item.omsetningsavgift.toFixed(2)}
                  </td>
                  <td className="border border-gray-400 p-2">
                    {item.taxameterCost.toFixed(2)}
                  </td>
                  <td className="border border-gray-400 p-2">
                    {item.fixedCosts.toFixed(2)}
                  </td>
                  <td className="border border-gray-400 p-2">
                    {item.licenseCost.toFixed(2)}
                  </td>
                  <td className="border border-gray-400 p-2">
                    {totalExpenses.toFixed(2)}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <div className="total-payment">
        <h2 className="text-lg font-bold mb-4">Total utbetaling:</h2>
        <table className="w-full border-collapse border border-gray-400">
          <thead>
            <tr>
              <th className="border border-gray-400 p-2">Total Innkjørt</th>
              <th className="border border-gray-400 p-2">Total Utgifter</th>
              <th className="border border-gray-400 p-2">Medlemsavgift</th>
              <th className="border border-gray-400 p-2">Netto Utbetaling</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="border border-gray-400 p-2">
                {summaryData.summary
                  .reduce(
                    (acc, item) =>
                      acc +
                      item.tt +
                      item.skole +
                      item.pasientreiser +
                      item.ordinær -
                      item.cash,
                    0
                  )
                  .toFixed(2)}
              </td>
              <td className="border border-gray-400 p-2">
                {summaryData.summary
                  .reduce(
                    (acc, item) =>
                      acc +
                      item.omsetningsavgift +
                      item.taxameterCost +
                      item.fixedCosts +
                      item.licenseCost,
                    0
                  )
                  .toFixed(2)}
              </td>
              <td className="border border-gray-400 p-2">
                {summaryData.medlemsAvgift.toFixed(2)}
              </td>
              <td className="border border-gray-400 p-2 font-bold">
                {(
                  summaryData.summary.reduce(
                    (acc, item) =>
                      acc +
                      item.tt +
                      item.skole +
                      item.pasientreiser +
                      item.ordinær -
                      item.cash,
                    0
                  ) -
                  summaryData.summary.reduce(
                    (acc, item) =>
                      acc +
                      item.omsetningsavgift +
                      item.taxameterCost +
                      item.fixedCosts +
                      item.licenseCost,
                    0
                  ) -
                  summaryData.medlemsAvgift
                ).toFixed(2)}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  ) : (
    <p>Ingen data funnet for valgt måned.</p>
  )}
</div>
  );
}

export default SummaryPage;
